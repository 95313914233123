<template>
    <div>
        <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item">
                <a class="nav-link" :class="{ active: activeTab === 'home' }" @click="setActiveTab('home')"
                    href="#home">Home</a>
            </li>

            <li class="nav-item">
                <a class="nav-link" :class="{ active: activeTab === 'clubs' }" @click="setActiveTab('clubs')"
                    href="#clubs">Clubs</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" :class="{ active: activeTab === 'sports' }" @click="setActiveTab('sports')"
                    href="#sports">Sports</a>
            </li>
        </ul>
        <div class="tab-content">
            <div class="tab-pane fade" :class="{ show: activeTab === 'home', active: activeTab === 'home' }" id="home">
                <table class="table table-borderless datatable">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Club Name</th>
                            <th scope="col">No of Students</th>
                            <th scope="col">Club Leader</th>
                            <th scope="col">Action</th>
                        </tr>
                    </thead>
                    <tbody v-if="loading">
                        <tr>
                            <td>
                                <h5>Loading data ...</h5>
                            </td>
                        </tr>
                    </tbody>
                    <tbody v-else>
                        <tr v-for="(club, index) in clubs" :key="club.id">
                            <th scope="row">{{ index + 1 }}</th>
                            <td>{{ club.name }}</td>
                            <td>{{ club.total_students }}</td>
                            <td v-if="club.leader" class="badge border-success border-1 text-success">

                                {{ club.leader.firstname }}
                                {{ club.leader.lastname }}
                            </td>
                            <td v-else class="badge border-danger border-1 text-danger">
                                No Leader Assigned
                            </td>
                            <td>
                                <button @click="openModal(club)" class="btn btn-dark btn-sm"><i
                                        class="bi bi-pencil-square"></i></button> |
                                <button @click="openMembersModal(club)" class="btn btn-primary btn-sm"><i
                                        class="bi bi-eye-fill"></i></button> |
                                <button @click="exportMemebrs(club.id)" class="btn btn-secondary btn-sm"><i
                                        class="bi bi-cloud-arrow-down-fill"></i></button>
                            </td>

                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="tab-fade pt-3" :class="{ show: activeTab === 'clubs', active: activeTab === 'clus' }"
                id="clubs">
                <p> Club Coming soon</p>
            </div>
        </div>

        <div v-if="showModal" class="modal">
            <div class="modal-content">
                <h4>Select a Leader for <strong> {{ selectedClub.name }}</strong> club</h4>
                <p v-if="selectedClub.students.length <= 0">No students available</p>
                <table v-else class="table">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Club Members</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="student in selectedClub.students" :key="student.id">
                            <td>
                                <input type="radio" name="leader" :value="student.id" v-model="selectedLeaderId" />
                            </td>
                            <td>
                                {{ student.firstname }}
                                {{ student.lastname }}
                            </td>

                        </tr>
                    </tbody>

                </table>
                <button class="btn btn-secondary btn-sm" @click="saveLeader">Save</button>
                <button class="btn btn-info btn-sm mt-2" @click="closeModal">Cancel</button>
            </div>
        </div>

        <div v-if="showMembersModal" class="modal">
            <div class="modal-content">
                <h4>List of <strong> {{ selectedClub.name }}</strong> club members</h4>
                <p v-if="selectedClub.students.length <= 0">No students available</p>
                <table v-else class="table">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Club Members</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(student, index) in selectedClub.students" :key="student.id">
                            <td>
                                {{ index + 1 }}
                            </td>
                            <td>
                                {{ student.firstname }}
                                {{ student.lastname }}
                            </td>

                        </tr>
                    </tbody>

                </table>
                <button class="btn btn-info btn-sm mt-2" @click="closeMembersModal">Cancel</button>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
import 'select2/dist/css/select2.css';
import 'select2';
import $ from 'jquery';
window.$ = window.jQuery = $;
export default {
    data() {
        return {
            clubs: [],
            showModal: false,
            showMembersModal: false,
            selectedClub: null,
            selectedLeaderId: null,
            loading: false,
            activeTab: 'home',
        }
    },
    methods: {
        async getClubs() {
            this.loading = true;
            let response = await axios.get('http://localhost:8000/api/club/club-students');
            this.clubs = response.data;
            this.loading = false
        },
        openModal(club) {
            this.selectedClub = club;
            this.selectedLeaderId = club.leader ? club.leader.id : null;
            this.showModal = true;
        },
        openMembersModal(club) {
            this.selectedClub = club;
            this.selectedLeaderId = club.leader ? club.leader.id : null;
            this.showMembersModal = true;
        },
        closeModal() {
            this.showModal = false;
            this.selectedClub = null;
            this.selectedLeaderId = null;
        },

        closeMembersModal() {
            this.showMembersModal = false;
            this.selectedClub = null;
            this.selectedLeaderId = null;
        },

        saveLeader() {
            // Save the selected leader (this logic should be implemented)
            if (!this.selectedLeaderId) {
                alert("Please, select a leader.");
                return
            }
            axios.post('/clubs/assign-leader', {
                club_id: this.selectedClub.id,
                student_id: this.selectedLeaderId,
            })
                .then((response) => {
                    this.closeModal();
                    this.getClubs();
                })
                .catch((error) => {
                    alert("An error occurred: " + error.response.data.message)
                });

        },
        exportMemebrs(clubId) {
            window.location.href = `/clubs/${clubId}/export-members`;
        },
        setActiveTab(tab) {
            this.activeTab = tab;
            this.$router.push({ query: { tab } });
        }
    },
    mounted() {
        this.getClubs();
    },
    created() {
        const tab = this.$route.query.tab;
        if (tab) {
            this.activeTab = tab;
        }
    },
    watch: {
        '$route.query.tab'(newTab) {
            this.activeTab = newTab || 'home'
        }
    }
}
</script>
<style scoped>
/* Basic modal styling */
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background: white;
    padding: 20px;
    border-radius: 5px;
    width: 400px;
    max-width: 100%;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1001;
}
</style>
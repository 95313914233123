<template>
    <div class="pagetitle">
        <h1>Clubs</h1>
        <nav class="mt-2">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><router-link to="#">Home</router-link></li>
                <li class="breadcrumb-item active">Clubs</li>
            </ol>
        </nav>
    </div>
    <section class="section">
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <div class=" p-2 mt-2 mb-3">
                            <button type="button" @click="openModal" class="btn btn-success btn-sm float-end"><i
                                    class="bi bi-plus"></i>
                                <b>New Club</b></button>

                        </div>
                        <div class="row mt-5" v-if="loading">
                            <div class="col-lg-12">
                                <h3>Loading Clubs ...</h3>
                            </div>
                        </div>
                        <div class="row mt-5" v-else>
                            <div class="col-lg-3" v-for="club in clubs" :key="club.id">
                                <!-- Card with header and footer -->
                                <div class="card">
                                    <router-link :to="{ name: 'clubDetails', params: { id: club.id } }">
                                        <div class="card-header bg-dark text-white">Club: {{ club.name }}</div>
                                        <div class="card-body">
                                            <div class="d-flex align-items-center">
                                                <div
                                                    class="card-icon rounded-circle d-flex align-items-center justify-content-center">
                                                    <i class="bi bi-people"></i>
                                                </div>
                                                <div class="ps-1 mt-3">
                                                    <h6>Members: {{ club.total_students }}</h6>
                                                </div>
                                            </div>
                                            <h6><b>Club Leader:</b><br />
                                                <p v-if="club.leader"
                                                    class="badge border-success border-1 text-success mt-3">
                                                    {{ club.leader.firstname }}
                                                    {{ club.leader.lastname }}
                                                </p>
                                                <p v-else class="badge border-danger border-1 text-danger mt-3">
                                                    No Leader assigned
                                                </p>
                                            </h6>
                                        </div>
                                    </router-link>
                                    <div class="card-footer">
                                        <button @click="confirmDeleteClub(club.id)"
                                            class="btn btn-danger btn-sm">Delete</button>
                                    </div>
                                </div><!-- End Card with header and footer -->
                                <hr />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="showModal" class="modal">
            <div class="modal-content">
                <h4>Register new club</h4>
                <div class="card">
                    <div class="card-body">
                        <div class="modal-body">
                            <form class="row g-3" @submit.prevent="registerClub">
                                <div class="col-md-12">
                                    <label for="inputName5" class="form-label">Club Name</label>
                                    <input type="text" class="form-control" v-model="form.name">
                                </div>
                                <div class="col-md-12">
                                    <label for="inputName5" class="form-label">Club Category</label>
                                    <select class="form-control" v-model.number="form.category">
                                        <option disabled value="">Select club category</option>
                                        <option v-for="cat in categories" :key="cat.id" :value="cat.id">{{
                                            cat.category_name }}</option>
                                    </select>
                                </div>
                                <div>
                                    <button type="submit" class="btn btn-primary ">Submit</button>
                                    <button type="button" @click="closeModal"
                                        class="btn btn-secondary m-2">Cancel</button>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </section>
</template>
<script>
import axios from 'axios';
export default {
    data() {
        return {
            clubs: [],
            categories: [],
            loading: false,
            showModal: false,
            form: {
                name: '',
                category: null,
            },
            activateTab: 'tab1'
        }
    },
    methods: {
        async getClubs() {
            // let response = await axios.get('http://localhost:8000/api/clubs');
            this.loading = true;
            let response = await axios.get('/club/club-students');
            this.clubs = response.data;
            console.log(this.clubs);
            this.loading = false;
        },
        async getCategories() {
            const response = await axios.get('/categories');
            this.categories = response.data;
            console.log(this.categories);

        },

        async registerClub() {
            try {
                await axios.post('/clubs', {
                    name: this.form.name,
                    category_id: this.form.category,
                });
                alert("Clubs registered successfully!");
                this.getClubs();
                this.form.name = '';
                this.form.category = '';
                this.showModal = false;
            } catch (error) {
                alert("Error registering for clubs");
            }
        },
        openModal() {
            this.showModal = true;
        },
        closeModal() {
            this.showModal = false;
            this.form.name = "";
        },
        confirmDeleteClub(clubId) {
            if (confirm("Are you sure you want to delete this club?")) {
                this.deleteClub(clubId);
            }
        },
        deleteClub(clubId) {
            axios
                .delete(`/clubs/${clubId}`)
                .then(response => {
                    alert(response.data.message);
                    // Remove the deleted club from the list
                    this.clubs = this.clubs.filter(club => club.id !== clubId);
                })
                .catch(error => {
                    console.error("Error deleting club:", error);
                    alert("There was an issue deleting the club.");
                });
        },
        setActiveTab(tab) {
            this.activateTab = tab;
            this.$router.push({ query: { tab } });
        }
    },
    created() {
        this.getClubs();
        this.getCategories();
        const tab = this.$route.query.tab;
        if (tab) {
            this.activateTab = tab;
        }
    },
    watch: {
        '$route.query.tab'(newTab) {
            this.activateTab = newTab || 'tab1';
        }
    },

}

</script>
<style scoped>
.error {
    color: red;
}

/* Basic modal styling */
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background: white;
    padding: 20px;
    border-radius: 5px;
    width: 400px;
    max-width: 100%;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1001;
}
</style>